import uniqueArray from 'util/uniqueArray';
import sortBy from 'lodash/sortBy';
import { SOCIAL_TYPE_AVAILABLE } from 'constants/socialTypeAvailable';
import { SOCIAL_TYPE_WITHOUT_PAGE } from '../../../../constants/socialTypeWithoutPage';


export default function setSocialTypes(sortedAccounts) {
  const types = sortedAccounts.filter(account => !account.accounts
    && (SOCIAL_TYPE_WITHOUT_PAGE.includes(account.type)
      || sortedAccounts.some(object => object.socialNetworkType && object.socialNetworkType === account.type && object.status === 'ACTIVE'))
  ).map(account => account.type);

  const result = uniqueArray(types).filter(name => SOCIAL_TYPE_AVAILABLE.includes(name)).map((name, index) => {
    const orederValue = () => {
      switch (name) {
        case "FACEBOOK":
          return 0;
        case "INSTAGRAM":
          return 1;
        case "TIKTOK":
          return 2;
        case "PINTEREST":
          return 3;
        case "TWITTER":
          return 4;
        case "LINKEDIN":
          return 5;
        default:
          return index + 999
      }
    };
    
    return {
      id: index,
      name: name,
      order: orederValue(),
    }
  });

  return sortBy(result, ['order']);
}
