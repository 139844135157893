import isEmpty from 'lodash/isEmpty';
import changeArrayToSelectLike from 'util/changeArrayToSelectLike'
import filterSocialPagesBySocialType from 'util/filterSocialPagesBySocialType'

const allTypesOfTemplate = [
  { value: "NEWS", label: "News" },
  { value: "VIDEO", label: "Video" },
  { value: "ARTICLE", label: "Articles" },
  { value: "PRODUCT_LINK", label: "Product link" },
  { value: "PRODUCT_ALBUM", label: "Product album" },
];

export function prepare(state, action){
  const status = _getStatus(action.data.status);
  const socialType = _getSocialType(action.data.socialType);
  const socialTypeWithObjects = socialType.value === "FACEBOOK" || socialType.value === "LINKEDIN" || socialType.value === "PINTEREST" ;
  const socialAccounts = _getSocialAccounts(action.data.socialAccounts, action.accountsList);
  const socialPages = _getSocialPages(socialTypeWithObjects, action.data.socialObjects, action.facebookPagesFromApi);
  const typeSelectData = _getTypeSelectData(socialType);
  const socialAccountsAdditionalData = _getSocialAccountsAdditionalData(socialType, action.accountsList);
  const socialPagesAdditionalData = _getSocialPagesAdditionalData(socialType, socialTypeWithObjects, socialAccounts, action.facebookPagesFromApi);
  const typeAdditionalData = _getTypeAdditionalData(action.data.type, action.categoriesList, action.topicsList);
  const typeAdditional = _getTypeAdditional(action.data.type, action.data, action.topicsList, action.categoriesList);
  const stockAndProductData = _getStockAndProductData(
    action.data.type,
    action.data,
    action.stockAdditionalData,
    action.featuresAdditionalData,
  );

  return Object.assign({}, state, {
    id: action.data.id,
    formValid:true,
    name: action.data.name,
    nameValid: true,

    status: status,
    statusValid: true,

    socialType: socialType,
    socialTypeValid: true,

    socialAccounts: socialAccounts,
    socialAccountsValid: true,

    socialPages: socialPages,
    socialPagesValid: socialTypeWithObjects,

    socialAccountsAdditionalData: socialAccountsAdditionalData,

    template: action.data.template,
    templateValid: true,
    facebookPagesFromApi: action.facebookPagesFromApi,
    socialPagesAdditionalData: socialPagesAdditionalData,

    type: allTypesOfTemplate.filter(type => type.value === action.data.type)[0],
    typeSelectData: typeSelectData,
    typeAdditional: typeAdditional,
    typeAdditionalValid: true,
    typeValid: true,
    loading: action.loading,

  }, typeAdditionalData, stockAndProductData);
}

const _getStatus = (status)=>{
  switch (status) {
    case "ACTIVE":
      return { value: "ACTIVE", label: "Active" };
    case "DRAFT":
      return { value: "DRAFT", label: "Draft" };
    case "TRASH":
      return { value: "TRASH", label: "Trash" };

    default:
      return null;
  }
};

const _getSocialType = (socialType)=>{
  switch (socialType) {
    case "FACEBOOK":
      return { value: "FACEBOOK", label: "Facebook" };
    case "INSTAGRAM":
      return { value: "INSTAGRAM", label: "Instagram" };
    case "TWITTER":
      return { value: "TWITTER", label: "Twitter" };
    case "PINTEREST":
      return { value: "PINTEREST", label: "Pinterest" };
    case "TIKTOK":
      return { value: "TIKTOK", label: "TikTok" };
    default:
      return null;
  }
};

const _getTypeSelectData = (socialType)=>{
  if(!isEmpty(socialType)){
    return {
      disabled: false,
      isMulti: false,
      placeholder: "Type of Post",
      name: "type",
      options: _getOptions(socialType.value),
    }
  } else {
    return {
      disabled: true,
      isMulti: false,
      placeholder: "First choose social type",
      name: "type",
      options: [],
    }
  }

  function _getOptions(socialTypeKey){
    switch (socialTypeKey) {
      case "FACEBOOK":
      case "PINTEREST":
        return allTypesOfTemplate;
      case "TIKTOK":
        return [{ value: "VIDEO", label: "Video" }];
      case "INSTAGRAM":
        return [{ value: "VIDEO", label: "Video" }, { value: "PRODUCT_ALBUM", label: "Product album" }];

      default:
        return []
    }
  }

};

const _getSocialAccounts = (accountsList, accountsListOptions)=>{
  let options = [
    {
      key: 666777,
      value: 666777,
      label: "Select all accounts",
    },
  ];

    if(accountsList.length === accountsListOptions.length){
      return options.concat(changeArrayToSelectLike(accountsList));
    } else {
      return changeArrayToSelectLike(accountsList);
    }

};

const _getSocialAccountsAdditionalData = (socialType, accountsList)=>{
  if(!isEmpty(socialType)){
    let options = [
      {
        key: 666777,
        value: 666777,
        label: "Select all accounts",
      },
    ];
    return {
      disabled: false,
      isMulti: true,
      placeholder: `${socialType.label} accounts`,
      name: "type",
      options: options.concat(changeArrayToSelectLike(accountsList.filter(account => account.type === socialType.value))),
    }
  } else {
    return {
      disabled: true,
        isMulti: true,
        placeholder: "First choose social media type",
        name: "socialAccounts",
        options: [],
    }
  }
};

const _getSocialPagesAdditionalData = function (socialType, socialTypeWithObjects, socialAccounts, facebookPagesFromApi){
  if(socialTypeWithObjects){
    let options = [
      {
        key: 666777,
        value: 666777,
        label: "Select all pages",
      },
    ];

    return {
      name: "socialPages",
      placeholder: `${socialType.label} pages`,
      disabled: false,
      isMulti: true,
      options: options.concat(filterSocialPagesBySocialType(facebookPagesFromApi, socialType.value)),
      value: [],
    }

  }
  else {
    return {
      name: "socialPages",
      placeholder: "First choose accounts",
      disabled: true,
      isMulti: true,
      options: [
        {
          key: 666777,
          value: 666777,
          label: "Select all pages",
        },
      ],
      value: [],
    }
  }

};

function _getSocialPages(socialTypeWithObjects, pages, facebookPagesFromApi) {
  let options = [
    {
      key: 666777,
      value: 666777,
      label: "Select all pages",
    },
  ];

  if(socialTypeWithObjects){
    if(pages.length === facebookPagesFromApi.length){
      return options.concat(changeArrayToSelectLike(pages));
    } else {
      return changeArrayToSelectLike(pages);
    }
  } else {
    return options;
  }
}

const _getTypeAdditionalData = (typeOfTemplate, categoriesList, topicsList) => {
  switch (typeOfTemplate) {
    case "NEWS":
    case "ARTICLE":
      return {
        typeAdditionalData: {
          disabled: false,
          isMulti: true,
          placeholder: typeOfTemplate === "NEWS" ? "News" : "Articles",
          options: [
            {
              key: 666777,
              value: 666777,
              label: "Select all categories",
            },
          ].concat(changeArrayToSelectLike(topicsList)),
        },
        tags: [{ id: "title", label: "Title" }, { id: "url", label: "Url" }, { id: "description", label: "Description" }, { id: "hashtags", label: "Hashtags" }],
      };

    case "PRODUCT_LINK":
    case "PRODUCT_ALBUM":
      return {
        typeAdditionalData: {
          disabled: false,
          isMulti: true,
          placeholder: "Product categories",
          options: [
            {
              key: 666777,
              value: 666777,
              label: "Select all categories",
            },
          ].concat(changeArrayToSelectLike(categoriesList)),
        },
        tags: [
          { id: "name", label: "Name" },
          { id: "url", label: "Url" },
          { id: "description", label: "Description" },
          { id: "hashtags", label: "Hashtags" },
          { id: "sku", label: "Sku" },
          { id: "brand", label: "Brand" },
          { id: "price", label: "Price" },
        ],
      };

    case "VIDEO":
      return {
        typeAdditionalData: {
          disabled: false,
          isMulti: true,
          placeholder: "Videos",
          options: [
            {
              key: 666777,
              value: 666777,
              label: "Select all categories",
            },
          ].concat(changeArrayToSelectLike(categoriesList)),
        },
        tags: [
          { id: "name", label: "Name" },
          { id: "url", label: "Url" },
          { id: "description", label: "Description" },
          { id: "hashtags", label: "Hashtags" },
          { id: "sku", label: "Sku" },
          { id: "brand", label: "Brand" },
          { id: "price", label: "Price" },
        ],
      };

    default:
      break;
  }
};

const _getTypeAdditional = (typeOfTemplate, templateData, topicsList, categoriesList) =>{

  let initial = [
    {
      key: 666777,
      value: 666777,
      label: "Select all categories",
    },
  ];

  switch (typeOfTemplate) {
    case "NEWS":
    case "ARTICLE":
      return topicsList.length === templateData.topics.length ? initial.concat(changeArrayToSelectLike(templateData.topics)) : changeArrayToSelectLike(templateData.topics);

    case "PRODUCT_LINK":
    case "PRODUCT_ALBUM":
      return categoriesList.length === templateData.productCategories.length ? initial.concat(changeArrayToSelectLike(templateData.productCategories)) : changeArrayToSelectLike(templateData.productCategories);

    case "VIDEO":
      return [];

    default:
      break;
  }
};

const _getStockAndProductData = (typeOfTemplate, templateData, stockAdditionalData, featuresAdditionalData) => {
  switch (typeOfTemplate) {
    case "PRODUCT_LINK":
    case "PRODUCT_ALBUM":
      return {
        stock: changeArrayToSelectLike(templateData.stockLevels),
        features: changeArrayToSelectLike(templateData.productFeatures),
        stockValid: true,
        featuresValid: true,
        stockAdditionalData: {
          options: changeArrayToSelectLike(stockAdditionalData),
        },
        featuresAdditionalData: {
          options: changeArrayToSelectLike(featuresAdditionalData),
        },
      };


    default:
      break;
  }
};
