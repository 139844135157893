import validUrl from 'util/validUrl';

export default function validatePostModal(state) {
  const { socialPages, link, content, extraPublishingParams } = state;

  const images = state.images.filter(image => image.status === 'ACTIVE');
  const videos = state.videos.filter(video => video.status === 'ACTIVE');
  const descLength = (content || '').trim().length;
  const isValidLink = validUrl(link || '');
  const somePageTypesExsist = socialPages.some(socialPage => !!socialPage.socialObjects?.length);

  const dataIsValid = socialPages
    .filter(socialPage => !!socialPage.socialObjects?.length)
    .every(socialPage => {
      const postType = extraPublishingParams[socialPage.socialType]?.postType;

      switch (socialPage.socialType) {
        case 'INSTAGRAM':
        case 'PINTEREST':
          return (!!images.length || !!videos.length) && postType;
        case 'TIKTOK':
          return (!!videos.length) && postType;
        case 'FACEBOOK':
        case 'TWITTER':
        case 'LINKEDIN':
          if ((descLength || !!images.length || isValidLink || !!videos.length) && postType) {
            return true;
          } else {
            return false;
          }

        default:
          return false;
      }
    });

  return {
    ...state,
    formValid: dataIsValid  && somePageTypesExsist
  };
}
